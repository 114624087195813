<template>
  <head>
    <meta name='viewport' content='width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0'/>
  </head>
  <van-nav-bar class="site-nav-bar" fixed>
    <template #left>
      <base-user-circle></base-user-circle>
    </template>
    <template #title>
      <router-link :to="{name:'home'}">
        <div class="v-align">
          <van-icon name="/media/logo/logo_512x512.png" size="2rem"/>
        </div>
      </router-link>
    </template>
    <template #right>
      <van-row gutter="12">
        <van-col span="11">
          <router-link :to="{name:'home'}">
            <van-icon name="search" color='gray' size="20"/>
          </router-link>
        </van-col>
        <van-col span="13">
          <router-link :to="{name:'home'}">
            <van-icon name="wap-nav" color='gray' size="20"/>
          </router-link>
        </van-col>
      </van-row>
    </template>
  </van-nav-bar>
  <van-notice-bar color="white" background="#1699f3" class="notice" left-icon="down" mode="closeable">
      <span class="notice-text">
        <a href="#" @click="onDownloadClick">{{ $t('MENU.DOWNLOAD', {name: 'DOWEING'}) }}</a>
      </span>
  </van-notice-bar>
  <van-popup v-model:show="showDownload" position="bottom" :style="{ height: '25%' }">
    <app-download></app-download>
  </van-popup>
  <van-popup v-model:show="showChat" position="right" class="wh-100" closeable>
    <chat-view></chat-view>
  </van-popup>
  <van-popup v-model:show="showShareLocation" position="right" class="wh-100" closeable>
    <share-location-view :group="group" @toggleShareLoc="onToggleShareLoc"></share-location-view>
  </van-popup>
  <map-kit-view :map-type="mapType"
                :map-center-count="mapCenterCount"
                :map-show-all-user-coordinate="mapShowAllUserCoordinate"
                :map-center-group-count="mapCenterGroupCount"
                :map-north-count="mapNorthCount"
                :map-focus-point="mapFocusPoint"
                @showPoint="onShowPoint"
                @toggleGpsFocus="onToggleGPSFocus"
  ></map-kit-view>
  <div class="float-left-bar" @click="onShowAllTTS">
    <div class="btn-round shadow large">
      <img class="fill" src="/media/icon/btn_mapview_avg_alert_normal@3x.png">
    </div>
  </div>
  <div class="float-right-bar">
    <div @click="onShareLocationClick" class="btn-round shadow medium">
      <img :src="`/media/icon/${mapShareLocImg}@3x.png`">
    </div>
    <div @click="onMapCenterGroupClick" class="btn-round shadow medium">
      <img src="/media/icon/ic_function_list_group_members_normal@3x.png">
    </div>
    <div @click="onMapCenterClick" class="btn-round shadow medium">
      <img :src="`/media/icon/${mapCenterImg}@3x.png`">
    </div>
    <div @click="onMapTypeClick" class="btn-round shadow medium">
      <img src="/media/icon/ic_map_satellite_normal@3x.png">
    </div>
    <div @click="onChatClick" class="btn-round shadow large primary">
      <img class="white" src="/media/icon/ic_gen_chatroom_normal@3x.png">
    </div>
  </div>

  <!--  <van-popup-->
  <!--      v-model:show="showPopUpContent"-->
  <!--      :style="{-->
  <!--        'max-height': '85%',-->
  <!--        'max-width': '500px',-->
  <!--        'width': '80%'-->
  <!--      }">-->
  <!--    <pop-up-post></pop-up-post>-->
  <!--  </van-popup>-->

  <van-popup
      v-model:show="showPopUpPointTTS"
      :style="{
          'max-height': '85%',
          'max-width': '500px',
          'width': '80%'
        }"
      closeable>
    <pop-up-point-text-speech :point-id="showPointIdTTS"></pop-up-point-text-speech>
  </van-popup>

  <van-popup
      v-model:show="showPopUpBottom"
      :style="{
        'max-height' : '25%',
        'max-width' : '500px',
        'width' : '80%' ,
        'border-radius':'10px',
        'margin' : 'auto auto 1% 50%',
        'transform' : 'translateX(-50%)'
      }"
      position="bottom">
    <base-pop-up-bottom></base-pop-up-bottom>
  </van-popup>

  <van-popup
      v-model:show="showPopUpPoint"
      class="pop-up-point"
      :style="'background-color:rgb(233, 233, 233);'"
      position='left'
      :overlay="false"
      closeable>
    <pop-up-point
        :point-id="showPointId"
        @showPointTTS="onShowPointTTS"
        @showMapDirection="onShowMapDirection">
    </pop-up-point>
  </van-popup>

  <van-popup
      v-model:show="showMapDirection"
      position="bottom"
      :style="{ height: '30%' }">
    <redirect-map :geolocation="geolocation"></redirect-map>
  </van-popup>

  <van-popup
      v-model:show="showAllTTS"
      class="pop-up-tts"
      position='left'
      :overlay="false"
      closeable>
    <van-swipe ref="root" @change="swipeTTS" class="my-swipe" indicator-color="white" :height="570">
      <van-swipe-item v-for="item in listTTS" :key="item.id" class="swipe-container">
        <pop-up-point-text-speech
            v-if="!((item.params ?? {}).resource_type !== 'audio_records')"
            :point-id="item.id"
            :voice-guide="true"
            @viewPoint="onViewPoint"></pop-up-point-text-speech>
        <pop-up-point
            v-else
            :point-id="item.id"
            @showPointTTS="onShowPointTTS"
            @showMapDirection="onShowMapDirection">
        </pop-up-point>
      </van-swipe-item>
    </van-swipe>
    <van-row class="slide-control">
      <van-col span="5">
        <van-icon @click="onChange('prev')" name="arrow-left" color="rgb(22, 153, 243)" size="30"/>
      </van-col>
      <van-col span="10" offset="4"></van-col>
      <van-col span="5">
        <van-icon @click="onChange('next')" name="arrow" style="float:right" color="rgb(22, 153, 243)" size="30"/>
      </van-col>
    </van-row>
  </van-popup>

</template>


<script>
import {reactive, ref} from 'vue';
import MapKitView from '@/view/components/view/MapKitView';
import BaseUserCircle from '@/view/components/ui/BaseUserCircle';
import AppDownload from "@/view/components/ui/AppDownload";
import ChatView from "@/view/components/view/ChatView";
import {isWindows, isMacOs, isIOS, isAndroid} from 'mobile-device-detect';
import PopUpPoint from '@/view/components/ui/PopUpPoint.vue';
import BasePopUpBottom from '@/view/components/ui/BasePopUpBottom';
import PopUpPointTextSpeech from '@/view/components/ui/PopUpPointTextSpeech'
import useGroupComp from "@/core/services/composables/useGroupComp";
import {APP_STORE_URL, PLAY_STORE_URL} from "@/config/api";
import {useRoute} from "vue-router";
import mapkit from "@solidsilver/mapkitjs";
import ShareLocationView from "@/view/components/view/ShareLocationView";
import RedirectMap from "@/view/components/ui/RedirectMap";
import {Toast} from "vant";

export default {
  setup() {
    const state = reactive({});
    const showDownload = ref(false);
    const showChat = ref(false);
    const showShareLocation = ref(false);
    const showMap = ref(true);
    const showPopUpBottom = ref(false);
    const showPopUpPoint = ref(false);
    const showPopUpPointTTS = ref(false);
    const showAllTTS = ref(false);
    const gpsFocused = ref(false);
    const groupComp = useGroupComp();
    const route = useRoute();
    const groupId = route.params.groupId ? route.params.groupId : 402;
    const mapType = ref(mapkit.Map.MapTypes.Standard);
    const indexMapType = ref(0);
    const mapCenterCount = ref(0);
    const mapCenterGroupCount = ref(0);
    const mapFocusPoint = ref(0);
    const mapNorthCount = ref(0);
    const mapShowAllUserCoordinate = ref(true);
    const group = ref({});
    const showPointId = ref(0);
    const showPointIdTTS = ref(0);
    const mapCenterJustClick = ref(0);
    const mapCenterImg = ref('ic_gen_goto_my_position_normal');
    const mapShareLocImg = ref('ic_function_list_gps_sharing_normal');
    const ctrlPressed = ref(false);
    const mapHover = ref(false);
    const showMapDirection = ref(false);
    const geolocation = ref([]);
    const root = ref(null);
    const listTTS = ref([]);
    groupComp.group(groupId).then(value => {
      group.value = value.data;
    })
    groupComp.points(groupId).then(value => {
      listTTS.value = value.data.items.filter(function (item) {
        if (item.type === 'audio_guide') {
          return item;
        }
      });
      // listTTS.value.push()
      // value.data.items.forEach(async function (element) {
      //   console.log(element.type);
      //   if (element.type === 'audio_guide') {
      //     if (element.params) {
      //       listTTS.value.push(element);
      //       // if (element.params.resource_type == 'audio_records') {
      //       //   listTTS.value.push(element.id);
      //       // }
      //     }
      //   }
      // })
    })

    // no zoom
    onkeydown = function (e) {
      if (e.keyCode == '17') {
        ctrlPressed.value = true;
      }
      if (ctrlPressed.value == true && e.keyCode == '187' || ctrlPressed.value == true && e.keyCode == '189') {
        e.preventDefault();
      }
    }
    onkeyup = function (e) {
      if (e.keyCode == '17') {
        ctrlPressed.value = false;
      }
    }
    addEventListener('wheel', function (e) {
      if (ctrlPressed.value == true && mapHover.value != true) {
        e.preventDefault();
      }
    }, {passive: false});

    addEventListener("mouseover", function (event) {
      if (event.toElement.className == 'mk-map-node-element') {
        mapHover.value = true;
      } else {
        mapHover.value = false;
      }
    }, false);
    // end no zoom desktop

    // zoom phone
    window.addEventListener("touchstart", function (e) {
      if (e.touches.length > 1) {
        e.preventDefault()
      }
    }, {passive: false});

    var timeout;
    var lastTap = 0;

    window.addEventListener('touchend', function (e) {
      var currentTime = new Date().getTime();
      var tapLength = currentTime - lastTap;
      clearTimeout(timeout);
      if (tapLength < 500 && tapLength > 0) {
        if (mapHover.value == false) {
          if (e.srcElement !== 'div.mk-map-node-element') {
            e.preventDefault();
          }
        }
      } else {
        timeout = setTimeout(function () {
          clearTimeout(timeout);
        }, 500);
      }
      lastTap = currentTime;
    });
    // end zoom phone

    // gyroscope
    var id, target, options;

    function success(pos) {
      var crd = pos.coords;

      if (target.latitude === crd.latitude && target.longitude === crd.longitude) {
        // console.log('Congratulations, you reached the target');
        navigator.geolocation.clearWatch(id);
      }
    }

    function error(err) {
      console.warn('ERROR(' + err.code + '): ' + err.message);
    }

    target = {
      latitude: 0,
      longitude: 0
    };

    options = {
      enableHighAccuracy: false,
      timeout: 5000,
      maximumAge: 0
    };

    id = navigator.geolocation.watchPosition(success, error, options);
    // end gyroscope

    const onViewPoint = (id) => {
      mapFocusPoint.value = id;
      showAllTTS.value = false;
      onShowPoint("audio_guide", id);
    }
    const onChange = (to) => {
      if (to == 'prev') {
        root.value.prev();
      } else {
        root.value.next();
      }
    }
    const onShowPoint = (type, id) => {
      showPopUpPoint.value = true;
      showPointId.value = id;
      showAllTTS.value = false;
    }
    const onShowPointTTS = (id) => {
      showPopUpPointTTS.value = !showPopUpPointTTS.value;
      showPointIdTTS.value = id;
    }
    const onShowAllTTS = () => {
      if (listTTS.value.length > 0) {
        showAllTTS.value = true;
      } else {
        Toast({
          message: "No Audio mp3",
          position: 'bottom',
        });
      }
    }
    const swipeTTS = (index) => {
      // console.log('change to ' + index);
    }
    const onShowMapDirection = (lat, long) => {
      showMapDirection.value = !showMapDirection.value;
      geolocation.value = [];
      geolocation.value.push(lat, long);
    }
    const onMapTypeClick = () => {
      const types = [mapkit.Map.MapTypes.Standard, mapkit.Map.MapTypes.Satellite, mapkit.Map.MapTypes.Hybrid];
      indexMapType.value = ++indexMapType.value % types.length;
      mapType.value = types[indexMapType.value];
    }
    const onMapCenterClick = () => {
      if (mapCenterImg.value === 'ic_gen_always_north_selected') {
        onMapNorthClick();
      } else {
        mapCenterCount.value += 1;
      }
    }
    const onMapNorthClick = () => {
      mapNorthCount.value += 1;
    }
    const onDownloadClick = () => {
      if (isIOS) {
        window.location.href = APP_STORE_URL;
      } else if (isAndroid) {
        window.location.href = PLAY_STORE_URL;
      } else if (isWindows || isMacOs) {
        showDownload.value = true;
      }
    }
    const onChatClick = () => {
      showChat.value = true;
    }
    const onShareLocationClick = () => {
      showShareLocation.value = true;
    }
    const onMapCenterGroupClick = () => {
      mapCenterGroupCount.value += 1;
    }
    const onToggleMapShowAllUserCoordinate = () => {
      mapShowAllUserCoordinate.value = !mapShowAllUserCoordinate.value;
    }
    const onTogglePostClick = () => {
      showPopUpPointTTS.value = !showPopUpPointTTS.value;
    }
    const onToggleGPSFocus = (value) => {
      if (value) {
        mapCenterImg.value = 'ic_gen_always_north_selected';
      } else {
        mapCenterImg.value = 'ic_gen_goto_my_position_normal';
      }
    }
    const onToggleShareLoc = (value) => {
      if (value) {
        mapShareLocImg.value = 'ic_function_list_gps_sharing_selected';
      } else {
        mapShareLocImg.value = 'ic_function_list_gps_sharing_normal';
      }
    }

    return {
      state,
      onTogglePostClick,
      onToggleGPSFocus,
      onDownloadClick,
      onChatClick,
      onChange,
      onShareLocationClick,
      onMapTypeClick,
      onMapCenterClick,
      onMapCenterGroupClick,
      onToggleMapShowAllUserCoordinate,
      onShowPoint,
      onShowPointTTS,
      onShowAllTTS,
      onViewPoint,
      onMapNorthClick,
      onToggleShareLoc,
      showAllTTS,
      listTTS,
      root,
      showMap,
      showDownload,
      showChat,
      showShareLocation,
      showPopUpBottom,
      showPopUpPoint,
      swipeTTS,
      group,
      geolocation,
      mapType,
      mapCenterCount,
      mapShowAllUserCoordinate,
      mapCenterGroupCount,
      mapFocusPoint,
      showPointId,
      showPopUpPointTTS,
      showPointIdTTS,
      mapNorthCount,
      mapCenterJustClick,
      mapCenterImg,
      mapShareLocImg,
      ctrlPressed,
      mapHover,
      showMapDirection,
      onShowMapDirection,

    }
  },
  components: {
    ShareLocationView,
    ChatView,
    AppDownload,
    MapKitView,
    BaseUserCircle,
    PopUpPoint,
    BasePopUpBottom,
    PopUpPointTextSpeech,
    RedirectMap
  }
}
</script>
