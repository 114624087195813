<template>
  <div class="container">
    <van-row v-if="message.changedate && message.placetop" class="my-2">
      <van-col class="date" offset="10" span="5">
        <text class="date-text">{{ message.changedate }}</text>
      </van-col>
      <van-col span="10"></van-col>
    </van-row>

    <div v-if="!message.is_self" class="chat-left">
      <van-row class="row">
        <van-col span="3">
          <base-user-circle :user-img="message.avatar_url"></base-user-circle>
        </van-col>
        <van-col span="12">
          <span class="name-left"><b>{{ message.name }}</b></span>
          <van-badge class="badge">
            <div v-if="messagetype == 'text'" class="chat-box-left">
              <span class="chat-text-left">{{ message.content }}</span>
              <span class="clock"> {{ message.time }} </span>
            </div>
            <div v-else-if="messagetype != 'text' || messagetype == 'picture'" class="photo-container-left">
              <img class="photo-content-left" :src="imgsrc"/>
              <span class="clock"> {{ message.time }} </span>
            </div>
            <div v-else class="chat-box-left">
              <span class="chat-text-left">Resource Not Found, type : {{ messagetype }}</span>
              <span class="clock"> {{ message.time }} </span>
            </div>
          </van-badge>
        </van-col>
      </van-row>
      <!-- <div class="left-content">
        <base-user-circle></base-user-circle>
      </div>
      <div class="right-content">
        <span class="name">{{ message.name }}</span>
        <van-badge class="badge">
          <div v-if="messagetype == 'text'" class="chat-box">
            <span class="chat-text">{{ message.content }}</span>
          </div>
          <div v-else-if="messagetype != 'text'" class="photo-container">
            <img class="photo-content" :src="imgsrc"/>
          </div>
          <div v-else class="chat-box">
            <span class="chat-text">Resource Not Found, type : {{ messagetype }}</span>
          </div>
        </van-badge>
        <span class="clock"> {{ message.time }} </span>
      </div> -->
    </div>
    <div v-else-if="message.is_self" class="chat-right">
      <van-row class="row">
        <van-col offset="10" span="14">
          <div class="right-content">
            <van-badge class="badge">
              <div v-if="messagetype == 'text'" class="chat-box-right">
                <span class="chat-text-right">{{ message.content }}</span>
                <span class="clock"> {{ message.time }} </span>
              </div>
              <div v-else-if="messagetype != 'text' || messagetype == 'picture'" class="photo-container-right">
                <img class="photo-content-right" :src="imgsrc"/>
                <span class="clock"> {{ message.time }} </span>
              </div>
            </van-badge>
          </div>
        </van-col>
        <!-- <div class="clock-container">
          <span class="clock">{{ message.time }}</span>
        </div>
        <div class="right-content">
          <div class="name">{{}}</div>
          <van-badge class="badge">
            <div v-if="messagetype == 'text'" class="chat-box">
              <span class="chat-text">{{ message.content }}</span>
            </div>
            <div v-else-if="messagetype != 'text'" class="photo-container-right">
              <img class="photo-content" :src="imgsrc"/>
            </div>
          </van-badge>
        </div> -->
      </van-row>
    </div>

    <van-row v-if="message.changedate && !message.placetop" class="my-2">
      <van-col class="date" offset="10" span="5">
        <text class="date-text">{{ message.changedate }}</text>
      </van-col>
      <van-col span="10"></van-col>
    </van-row>
  </div>
</template>

<script>
import BaseUserCircle from '@/view/components/ui/BaseUserCircle.vue';
import {ref, computed} from "vue";
import usePostComp from "@/core/services/composables/usePostComp";

export default {
  props: {
    message: Object,
  },
  components: {
    BaseUserCircle
  },
  setup(props) {
    const postComp = usePostComp();
    // console.log(props.message);
    const imgsrc = ref("");
    const messagetype = computed(() => {
      if (props.message.type == "text") {
        return props.message.type;
      } else if (props.message.type == 'picture.sent') {
        imgsrc.value = props.message.url;
        return 'picture';
      } else {
        if (props.message.resource_type === 'post') {
          postComp.post(props.message.resource_id).then((val) => {
            imgsrc.value = val.data.cover_url;
          });
        } else {
          imgsrc.value = '/media/images/messages/' + props.message.resource_type + '.png';
        }
        return props.message.resource_type;
      }
    });
    // console.log(props.message);
    return {
      messagetype,
      imgsrc
    };
  },
}
</script>

<style scoped>

.date {
  text-align: center;
  background-color: gray;
  border-radius: 15px;
  padding: 3px;
  padding-left: 4px;
  padding-right: 4px;
  font-size: 12px;
  color: white;
}

.date-text {
  white-space: nowrap;
}

.row {
  margin-bottom: 10px;
}

.name-left {
  display: block;
  margin-bottom: 2px;
}

.chat-box-left {
  position: relative;
  max-width: 100%;
  min-width: 100px;
  background: #f2f3f5;
  border-radius: 10px;
  border-top-left-radius: 0px;
}

.chat-text-left {
  display: block;
  padding: 10px;
  text-align: left;
  word-wrap: break-word;
}

.chat-box-left > .clock {
  position: absolute;
  font-size: 12px;
  margin-top: -19px;
  right: 0px;
  color: gray;
  transform: translate(105%, 0px);
}

.photo-content-left {
  width: 100%;
  border-radius: 10px;
}

.photo-container-left {
  position: relative;
  max-width: 80%;
}

.photo-container-left > .clock {
  position: absolute;
  font-size: 12px;
  bottom: 6px;
  right: 0px;
  color: gray;
  transform: translate(105%, 0px);
}

/* right */

.right-content {
  float: right;
}

.chat-box-right {
  float: right;
  min-width: 100px;
  background: #128EC8;
  color: white;
  border-radius: 10px;
  border-top-right-radius: 0px;
}

.chat-text-right {
  display: block;
  padding: 10px;
  text-align: left;
  word-wrap: break-word;
}

.photo-container-right {
  position: relative;
  max-width: 80%;
  float: right;
}

.photo-content-right {
  width: 100%;
  border-radius: 10px;
}

.chat-box-right > .clock {
  position: absolute;
  font-size: 12px;
  bottom: 4px;
  left: 0px;
  transform: translate(-105%, 0px);
  color: gray;
}

.photo-container-right > .clock {
  position: absolute;
  font-size: 12px;
  bottom: 4px;
  left: 0px;
  transform: translate(-105%, 0px);
  color: gray;
}

.clock {
  padding: 0 4px;
}
</style>
