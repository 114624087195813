<template>
  <van-nav-bar class="site-nav-bar" :title="group.name"></van-nav-bar>
  <van-cell-group title="Share Location">
    <van-cell center :title="state.time">
      <template #right-icon>
        <van-switch :model-value="state.isOn" size="24" @update:model-value="onConfirmShare"/>
      </template>
    </van-cell>
  </van-cell-group>
  <van-cell-group title="Interval">
    <van-field name="slider" :label="state.interval">
      <template #input>
        <van-slider v-model="state.interval" :min="10" :max="100"/>
      </template>
    </van-field>
  </van-cell-group>
</template>

<script>
import {computed, reactive} from 'vue';
import {Dialog, Toast} from "vant";
import moment from "moment";
import {useRoute} from "vue-router";
import useAuthComp from "@/core/services/composables/useAuthComp";
import useLocationComp from "@/core/services/composables/useLocationComp";

export default {
  props: {
    group: Object,
  },
  emits: ['toggleShareLoc'],
  setup(props, context) {
    const state = reactive({
      interval: 10,
      time: "--:--:--",
      isOn: false,
      login: computed(() => {
        return authComp.check();
      }),
    });
    const authComp = useAuthComp();
    const locationComp = useLocationComp();
    const timeFormat = 'HH:mm:ss';
    const offTime = "--:--:--";
    const stopTime = "00:00:00";
    const defaultTime = "08:00:00";
    const stop = moment(stopTime, timeFormat);
    const route = useRoute();
    const groupId = route.params.groupId ? route.params.groupId : 402;

    const onStartShare = () => {
      const now = moment(state.time, timeFormat);
      const isStop = !now.isSame(stop);
      if (state.isOn && isStop) {
        setTimeout(() => {
          if (now.seconds() % state.interval === 1) {
            onPushLocation();
          }
          onStartShare()
        }, 1000)
        const next = now.subtract(1, 'seconds');
        state.time = next.format(timeFormat)
      } else {
        onStopShare();
      }
    }
    const onPushLocation = () => {
      // const channel = pusherComp.subscribe(`${PUSHER_channelGroup}${groupId}`);
      // const lng = 121.1
      // const lat = 25.1;
      // locationComp.create({body: {group_id: groupId, lat: lat, lng: lng}});
       authComp.gps().then(position => {
        const lat = position.coords.latitude;
        const lng = position.coords.longitude;
        locationComp.create({body: {group_id: groupId, lat: lat, lng: lng}});
      }).catch(err => {
        Toast({
          message: `Sharing Location ${err.toString()}`,
          position: 'bottom',
        });
      });
    }
    const onStopShare = () => {
      state.time = offTime;
      state.isOn = false;
      context.emit('toggleShareLoc', state.isOn);
    }
    const onConfirmShare = (newValue) => {
      if (!state.login) {
        Toast("Guest can't share location.");
        return;
      }
      Dialog.confirm({
        title: 'Confirm',
        message: 'Start Shared Location?',
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
      }).then(() => {
        state.isOn = newValue;
        if (state.isOn) {
          context.emit('toggleShareLoc', state.isOn);
          state.time = defaultTime;
          onStartShare();
        } else {
          onStopShare();
        }
      });
    };
    return {
      onStartShare,
      onConfirmShare,
      state,
    };
  },
}
</script>

<style scoped>
/*.van-cell {*/
/*  background: #969799;*/
/*}*/
</style>
