<template>
  <h3 class="text-center my-1">GET DIRECTION</h3>
  <van-grid :border="false" :column-num="2">
    <van-grid-item>
      <a :href="urlGoogleMap">
        <van-image class="download-logo" src="/media/logo/googlemap.png"/>
      </a>
      Google Map
    </van-grid-item>
    <van-grid-item>
      <a :href="urlAppleMap">
        <van-image class="download-logo" src="/media/logo/applemap.png"/>
      </a>
      Apple Map
    </van-grid-item>
  </van-grid>
</template>

<script>
import {ref, watch} from 'vue';
export default {
  props: ['geolocation'],
  setup(props) {
    const urlGoogleMap = ref("https://www.google.com/maps/dir//"+props.geolocation[0]+","+props.geolocation[1]);
    const urlAppleMap = ref("http://maps.apple.com/?daddr="+props.geolocation[0]+","+props.geolocation[1]);
    watch(() => props.geolocation, (newValue, oldValue) => {
        urlGoogleMap.value = "https://www.google.com/maps/dir//"+newValue[0]+","+newValue[1];
        urlAppleMap.value = "http://maps.apple.com/?daddr="+newValue[0]+","+newValue[1];
    })
    return{
        urlGoogleMap,
        urlAppleMap
    }
  },
}
</script>