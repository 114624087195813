<template>
  <h5 class="text-center my-1">{{ $t('MENU.DOWNLOAD', {name: 'DOWEING'}) }}</h5>
  <van-grid :border="false" :column-num="2">
    <van-grid-item>
      <a :href="urlPlayStore">
        <van-image class="download-logo" src="/media/images/googleplay.png"/>
      </a>
    </van-grid-item>
    <van-grid-item>
      <a :href="urlAppStore">
        <van-image class="download-logo" src="/media/images/appstore.png"/>
      </a>
    </van-grid-item>
  </van-grid>
</template>

<script>
export default {
  setup() {
    const urlPlayStore = "https://play.google.com/store/apps/details?id=com.fusionnextinc.doweing&hl=zh_TW";
    const urlAppStore = "https://apps.apple.com/tw/app/doweing/id1348142189";
    return {
      urlPlayStore,
      urlAppStore,
    }
  },
}
</script>