<template>
  <div class="container">
    <div class="top-content">
      <div class="top-part">
        <div class="left">
          <div class="speed_cam" v-show="pointType === 'speed_cam'">
            <text><b>Fixed Speed Camera</b></text>
            <text class="blue">All directions</text>
            <text><b>3.68 km</b></text>
            <text class="gray">Current speed?? km/h</text>
          </div>
          <div class="poi" v-show="pointType !== 'speed_cam'">
            <text><b>{{ title }}</b></text>
            <text><b>3.68 km</b></text>
            <text class="gray">{{ description }}</text>
          </div> 
        </div>
        <div class="middle">
          <div class="speed_limit_container" v-if="pointImage == 'speed_cam'">
            <van-image
                width="100%"
                :src="'/media/images/'+pointImage+'.png'"
            />
            <span class="speed_limit_text">{{ speed_limit }}</span>
          </div>
          <div class="emoji_container" v-else-if="pointImage != 'audio_guide'">
            <van-image
                width="100%"
                :src="'/media/images/'+pointImage+'.png'"
            />
            <span class="emoji_logo">{{ emoji_logo }}</span>
          </div>
          <div class="audio-guide-container" @click="onClickPlayTTS" v-else>
            <img width="90" height="90" :src="pictures[0] ?? '/media/images/'+pointImage+'.png'"/>
            <div v-if="pointAudio == 'text_to_speech'" class="audio-guide-play">
              <van-icon v-if="!pause" name="play-circle-o" size="2rem"/>
              <van-icon v-else name="stop-circle-o" size="2rem"/>
            </div>
          </div>
        </div>
        <div class="right">
          <!-- <img v-show="pointType !== 'speed_cam'" src="/media/icon/ic_basic_more_disable.png" class="photo-right"> -->
        </div>
      </div>
      <hr class="line">
      <div class="bottom-part">
        <div class="left">
          <img src="/media/images/right.png" @click="showMap" class="turn-right">
        </div>
        <div class="right">
          <img src="/media/icon/ic_basic_more_disable.png">
        </div>
      </div>
    </div>

    <div class="userinfo">
      <div class="left">
        <img :src="userImg" class="profile-img">
      </div>
      <div class="right">
        <text><b>{{ fullName }}</b></text>
        <text class="date">{{ date }}</text>
      </div>
    </div>

    <div class="locationinfo">
      <div class="left">
        <van-icon name="location-o" size="1.5rem"/>
      </div>
      <div class="middle">
        Taiwan 110 Xinyi District
      </div>
      <div class="right">
        <img src="/media/images/right.png" @click="showMap" class="photo-right">
      </div>
    </div>

    <div class="description">
      <div class="col">
        {{ description ?? 'No Description' }}
      </div>
    </div>

    <div class="pictures">
      <van-row :gutter="10">
        <van-col span="8" v-for="(val, i) in pictures" :key="val">
          <van-image
              @click="onImagePreview(i)"
              height="120px"
              radius="10px"
              :src="val"
          />
        </van-col>
      </van-row>
    </div>
    <div class="userchat">
      <div class="left">
        <img src="/media/icon/ic_tabbar_personal_set_normal.png" class="profile-img grayback">
      </div>
      <div class="right">
        <van-field
            v-model="comment"
            placeholder="Write a comment.."
            autosize
            class="field-chat"
        />
      </div>
    </div>
  </div>
</template>

<script>
import {ref, watch, toRefs} from 'vue';
import {useRoute} from "vue-router";
import usePointComp from "@/core/services/composables/usePointComp";
import useGroupComp from "@/core/services/composables/useGroupComp";
import {ImagePreview} from "vant";

export default {
  props: {
    pointId: Number,
  },
  emits: ['showPointTTS','showMapDirection'],
  setup(props, context) {
    const {pointId} = toRefs(props);
    const pointComp = usePointComp();
    const groupComp = useGroupComp();
    const route = useRoute();
    const title = ref();
    const fullName = ref();
    const date = ref();
    const userImg = ref();
    const description = ref();
    const groupId = route.params.groupId ? route.params.groupId : 402;
    const pointType = ref();
    const pointImage = ref();
    const pointAudio = ref();
    const comment = ref();
    const pause = ref();
    const pictures = ref([]);
    // const audioType = ref([]);
    const synth = window.speechSynthesis;
    const speed_limit = ref(0);
    const emoji_logo = ref("");
    let point = {};

    const showMap = function(){
      context.emit('showMapDirection',point.lat,point.lng);
    }

    const onUpdateInterface = function (id) {
      pointComp.point(id).then(val => {
        // console.log(val);
        point = val.data;
        pointImage.value = val.data.type;
        pointType.value = val.data.type;
        pointAudio.value = (point.params ?? {}).resource_type;
        description.value = val.data.description;
        if (pointType.value == 'speed_cam') {
          speed_limit.value = val.data.params.speed_limit;
        }
        emoji_logo.value = val.data.emoji;
        title.value = val.data.title;
        groupComp.users(groupId).then(value => {
          const user = value.data.items.find((item) => Number(item.id) === Number(val.data.user_id));
          userImg.value = user.avatar_url;
          fullName.value = user.full_name;
          date.value = user.created_at;
        })
        pictures.value = [];
        pointComp.pictures(id).then(pict => {
          pict.data.items.forEach(function (k) {
            pictures.value.push(k.url);
          });
        })
      })
    };
    const onClickPlayTTS = function () {
      // console.log(point.description);
      if (point.type === 'audio_guide') {
        if ((point.params ?? {}).resource_type !== 'audio_records') {
          const speech = new window.SpeechSynthesisUtterance(((point.params ?? {}).tts_content) ?? point.description);
          if (!synth.speaking) {
            speech.onstart = function (event) {
              pause.value = true;
              // console.log('onstart')
            }
            speech.onpause = function (event) {
              pause.value = false;
              // console.log('onpause')
            }
            speech.onresume = function (event) {
              pause.value = true;
              // console.log('onresume')
            }
            speech.onend = function (event) {
              pause.value = false;
              // console.log('onend')
            }
            synth.speak(speech);
          } else {
            if (synth.paused) {
              synth.resume();
            } else {
              synth.pause();
            }
          }
        } else {
          context.emit('showPointTTS', pointId.value);
        }
      }
      else if(point.description != null){
        const speech = new window.SpeechSynthesisUtterance(((point.params ?? {}).tts_content) ?? point.description);
          if (!synth.speaking) {
            speech.onstart = function (event) {
              pause.value = true;
              // console.log('onstart')
            }
            speech.onpause = function (event) {
              pause.value = false;
              // console.log('onpause')
            }
            speech.onresume = function (event) {
              pause.value = true;
              // console.log('onresume')
            }
            speech.onend = function (event) {
              pause.value = false;
              // console.log('onend')
            }
            synth.speak(speech);
          } else {
            if (synth.paused) {
              synth.resume();
            } else {
              synth.pause();
            }
          }
      }
    }
    const onImagePreview = function (position) {
      ImagePreview({
        images: pictures.value,
        startPosition: position,
        closeable: true,
      });
    }
    onUpdateInterface(pointId.value);
    watch(() => props.pointId, (newValue, oldValue) => {
      onUpdateInterface(newValue);
    })
    return {
      onClickPlayTTS,
      onImagePreview,
      groupId,
      route,
      userImg,
      title,
      fullName,
      date,
      description,
      comment,
      pointType,
      pointImage,
      pointAudio,
      pictures,
      showMap,
      speed_limit,
      emoji_logo,
      pause
    }
  }
}
</script>

<style scoped>
.container {
  width: 100%;
  overflow: hidden;
}

.container > .top-content {
  display: inline-grid;
  background-color: white;
  width: 100%;
  content: "";
  clear: both;
  padding-left: 4%;
  padding-right: 4%;
  padding-top: 7%;
}

.left {
  display: block;
  /* padding: 3%; */
  /* background: rgb(255, 255, 255); */
  float: left;
  width: 60%;
}

.middle {
  display: block;
  border-radius: 50%;
  float: left;
  width: 20%;
}

.right {
  display: block;
  /* background: cyan; */
  float: left;
  width: 12%;
}

.photo-middle {
  width: 100%;
}

.photo-right {
  margin-top: 15%;
  margin-left: 10%;
  width: 100%;
}

.blue {
  color: rgb(0, 162, 255);
  font-size: 12px;
}

.gray {
  color: gray;
  font-size: 12px;
}

.grayback {
  background-color: rgb(233, 233, 233);
}

text {
  display: block;
  margin-top: 3px;
  margin-bottom: 3px;
}

.line {
  float: left;
  width: 85%;
  margin-left: 0px;
  margin-top: 0.5%;
}

/* .container > .top-content > .left > .top-part{
  display: block;
  width: 100%;
  background-color: rgb(13, 183, 235);
}

.container > .top-content > .left > .bottom-part{
  display: block;
  width: 100%;
  background-color: rgb(13, 183, 235);
} */

.bottom-part {
  width: 100%;
}

.bottom-part > .left {
  float: left;
  width: 85%;
}

.bottom-part > .right {
  float: left;
  width: 10%;
}

.turn-right {
  width: 12%;
  float: left;
  margin-top: 0px;
  margin-bottom: 5px;
}

.userinfo {
  display: inline-flex;
  background-color: white;
  margin-top: 10%;
  margin: 2.5%;
  width: 92%;
  min-height: 25px;
  border-radius: 4px;
  padding: 1.5%;
  font-size: 13px;
}

.userinfo > .left {
  float: left;
  width: 13%;
}

.userinfo > .right {
  float: left;
  margin-left: 3%;
  width: 60%;
}

.profile-img {
  width: 100%;
  border-radius: 50%;
}

.locationinfo {
  display: inline-flex;
  background-color: white;
  margin: 2.5%;
  margin-top: 0px;
  width: 92%;
  min-height: 25px;
  border-radius: 4px;
  padding: 1.5%;
  font-size: 14px;
}

.locationinfo > .left {
  float: left;
  width: 15%;
  margin-left: 2%;
  margin-top: 2px;
}

.locationinfo > .middle {
  float: left;
  width: 75%;
  line-height: 28px;
}

.locationinfo > .right {
  float: left;
  margin-left: 3%;
  width: 10%;
  margin-top: -5px;
  margin-bottom: -10px;
}

.description {
  display: inline-flex;
  background-color: white;
  margin: 2.5%;
  margin-top: 0px;
  width: 92%;
  min-height: 25px;
  border-radius: 4px;
  padding: 1.5%;
  font-size: 14px;
}

.description > .col {
  width: 100%;
  font-size: 13px;
  margin: 2%;
}

.date {
  font-size: 12px;
  color: gray;
}

.userchat {
  display: inline-flex;
  background-color: white;
  margin: 2.5%;
  margin-top: 4px;
  width: 92%;
  min-height: 25px;
  border-radius: 4px;
  padding: 1.5%;
  font-size: 13px;
  height: 48px;
}

.userchat > .left {
  float: left;
  margin-top: 3px;
  /* padding: 3%; */
  width: 40px;
  margin-left: 10px;
}

.userchat > .right {
  float: left;
  width: 80%;
  margin-left: 10px;
  margin-top: 4px;
}

.field-chat {
  background-color: rgb(233, 233, 233);
  border-radius: 50px;
  height: 38px;
}

.pictures {
  margin-left: 2.5%;
  margin-right: 2.5%;
}

.pictures > .item {
  width: 100%;
}

.audio-guide-container {
  position: relative;
}

.audio-guide-container > img {
  border-radius: 10px;
  border: 2px solid black;
}

.audio-guide-container > .audio-guide-play {
  position: absolute;
  margin-left: 29px;
  margin-top: 32px;
  top: 0;
}

.speed_limit_container {
  position: relative;
  width: 100%;
  width: 90px;
}

.emoji_container, .speed_limit_container {
  position: relative;
  text-align: center;
}

.speed_limit_text {
  position: absolute;
  font-weight: bold;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 30px;
}

.emoji_logo {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 30px;
  margin-top: -3px;
}
</style>