<template>
  <van-nav-bar class="site-nav-bar" title="Chat" fixed></van-nav-bar>
  <van-popup
      v-model:show="show"
      position="left"
      :style="{ width: '30%', height:'100%' }"
  />
  <div class="container-chat">
    <base-chat-badge v-for="message in messages" :key="message.id" :message="message"></base-chat-badge>
  </div>
  <van-action-bar v-if="login" class="input-chat-box">
    <van-action-bar-icon icon="add-o"/>
    <van-action-bar-icon icon="photo-o"/>
    <van-field
        v-model="text"
        placeholder="Message"
        autosize
        @keyup.enter="onSendText"
    />
    <van-action-bar-icon icon="arrow" @click="onSendText"/>
  </van-action-bar>
</template>

<style scoped>
.container-chat {
  display: flex;
  flex-direction: column-reverse;
  overflow-y: scroll;
  overflow-x: hidden;
  width: 95%;
  margin-top: 50px;
  margin-left: auto;
  margin-right: auto;
  /*height: calc(100% - 100px);*/
  height: calc(100% - 60px);
}

</style>

<script>
import {reactive, ref} from 'vue';
import BaseChatBadge from '@/view/components/ui/BaseChatBadge.vue';
import {PUSHER_channelGroup, PUSHER_eventMessageCreated} from "@/config/api";
import useGroupComp from "@/core/services/composables/useGroupComp";
import {useRoute} from "vue-router";
import usePusherComp from "@/core/services/composables/usePusherComp";
import useAuthComp from "@/core/services/composables/useAuthComp";
import useMessageComp from "@/core/services/composables/useMessageComp";
import {Toast} from "vant";
import moment from "moment";

export default {
  components: {BaseChatBadge},
  setup: function () {
    const state = reactive({});
    const show = ref(false);
    const messages = ref();
    const text = ref("");
    const pusherComp = usePusherComp();
    const authComp = useAuthComp();
    const groupComp = useGroupComp();
    const messageComp = useMessageComp();
    const route = useRoute();
    const userList = ref([]);
    const dateTemp = ref([]);
    const groupId = route.params.groupId ? route.params.groupId : 402;
    const channel = pusherComp.subscribe(`${PUSHER_channelGroup}${groupId}`);
    const self = authComp.user();
    const login = authComp.check();
    const onSendText = () => {
      if (text.value === "") return
      messageComp.create({body: {type: "text", group_id: groupId, content: text.value}}
      ).then(response => {
        const mss = {
          content: text.value,
          user_id: self.id,
          created_at: undefined,
        }
        messages.value.unshift(formatMessage(mss));
        text.value = "";
      }).catch(err => {
        Toast({
          message: err.message,
          position: 'bottom',
        });
      })
    };

    groupComp.users(groupId).then(value => {
      userList.value.push(value);
    })
    const formatMessage = (msg,pusher=false) => {
        let val = msg;
        const time = moment.utc(msg.created_at, 'YYYY-MM-DDTHH:mm').local();
        const yesterday = moment().add(-1, 'days');
        if (msg.created_at !== undefined) {
          val['time'] = time.format('HH:mm');
        } else {
          val['time'] = "";
          val['type'] = 'text';
        }
        val['is_self'] = self.id === msg.user_id;
        if(userList.value.length > 0){
          val["avatar_url"] = userList.value[0].data.items.find((item) => Number(item.id) === Number(msg.user_id)).avatar_url ?? '';
          val["name"] = userList.value[0].data.items.find((item) => Number(item.id) === Number(msg.user_id)).full_name;
        }
        if(pusher == true){
          if (dateTemp.value[dateTemp.value.length-1] !== time.format('YYYY-MM-DD')) {
            if (time.format('YYYY-MM-DD') == moment().format('YYYY-MM-DD')) {
              val['placetop'] = true; 
              val['changedate'] = 'Today';
            }
            dateTemp.value.push(time.format('YYYY-MM-DD')); 
          }
        }
        else{
          if (dateTemp.value[dateTemp.value.length-1] !== time.format('YYYY-MM-DD')) {
            if (dateTemp.value[dateTemp.value.length-1] == yesterday.format('YYYY-MM-DD')){
              val['changedate'] = 'Yesterday';
            } else if (dateTemp.value[dateTemp.value.length-1] == moment().format('YYYY-MM-DD')) {
              val['changedate'] = 'Today';
            } else {
              val['changedate'] = dateTemp.value[dateTemp.value.length - 1];
            }
            dateTemp.value.push(time.format('YYYY-MM-DD')); 
          } else {
            val['changedate'] = false;
          }
        }
        return val;
    };
    channel.bind(PUSHER_eventMessageCreated, v => {
      v.message.user_id = v.creator.id;
      // messages.value = messages.value.filter(item => item.time !== "" && item.content !== v.message.content);
      // console.log(messages.value);
      messages.value.unshift(formatMessage(v.message,true));
    });
    groupComp.messages(groupId, {body: {sort: 'created_at', order: 'desc'}}).then(value => {
      // console.log(value.data);
      const data = value.data.items.map(function (c) {
        return formatMessage(c);
      });
      messages.value = data;
    })
    return {
      onSendText,
      state,
      login,
      show,
      text,
      messages,
      userList,
      dateTemp,
    };
  },
}
</script>
