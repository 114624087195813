<template>
  <div class="outer-circle shadow">
    <slot>
      <img class="photo" :src="img" alt="profile">
    </slot>
  </div>
</template>

<script>
import useAuthComp from "@/core/services/composables/useAuthComp";
import {ref} from "vue";

export default {
  props: {
    userImg: {
      type: String,
      required: false
    }
  },
  setup(props) {
    const authComp = useAuthComp();
    const user = authComp.user();
    const img = props.userImg ? props.userImg : ref(user.avatar_url ?? '/media/images/user.png');
    return {img}
  }
}
</script>

<style scoped>
.outer-circle {
  display: block;
  background-color: white;
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
}

.shadow {
  box-shadow: 1.5px 1.5px 2px rgb(126, 125, 125);
}

.photo {
  margin-top: 0.1rem;
  width: 1.8rem;
  height: 1.8rem;
  border-radius: 50%;
}
</style>