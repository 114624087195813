import {useAuth} from '@websanova/vue-auth/src/v3.js';
import {usePusher} from "@/core/module/pusherModule";

export default function usePusherComp() {
    const _pusher = usePusher();
    const _auth = useAuth();

    function pusher() {
        const token = _auth.token(_auth.options.tokenName);
        if (token !== null && token !== "") {
            _pusher.config.auth = {
                headers: {
                    Authorization: 'Bearer ' + _auth.token(_auth.options.tokenName),
                }
            }
        }
        return _pusher;
    }

    function subscribe(key) {
        return pusher().subscribe(key);
    }

    function bind(key) {
        return pusher().bind(key);
    }

    return {
        subscribe,
        bind,
    }
}