import {useAuth} from '@websanova/vue-auth/src/v3.js';
import {API_ROUTE, GET} from "@/config/api";

export default function useRouteComp() {
    const http = useAuth();

    function route(routeId, data) {
        data = data || {};
        return new Promise((resolve, reject) => {
            http.fetch({
                method: GET,
                url: `${API_ROUTE}/${routeId}`,
                data: data.body,
            }).then((res) => {
                resolve(res);
            }, reject);
        });
    }

    function posts(routeId, data) {
        data = data || {};
        return new Promise((resolve, reject) => {
            http.fetch({
                method: GET,
                url: `${API_ROUTE}/${routeId}/posts`,
                data: data.body,
            }).then((res) => {
                resolve(res);
            }, reject);
        });
    }

    return {
        route,
        posts,
    }
}