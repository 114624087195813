import {useAuth} from '@websanova/vue-auth/src/v3.js';
import {API_GROUP, GET} from "@/config/api";

export default function useGroupComp() {
    const http = useAuth();
    // let groupUsers = {};

    function groups(data) {
        data = data || {};
        return new Promise((resolve, reject) => {
            http.fetch({
                method: GET,
                url: API_GROUP,
                data: data.body,
            }).then((res) => {
                resolve(res);
            }, reject);
        });
    }

    function group(groupId, data) {
        data = data || {};
        return new Promise((resolve, reject) => {
            http.fetch({
                method: GET,
                url: `${API_GROUP}/${groupId}`,
                data: data.body,
            }).then((res) => {
                resolve(res);
            }, reject);
        });
    }

    function routes(groupId, data) {
        data = data || {};
        return new Promise((resolve, reject) => {
            http.fetch({
                method: GET,
                url: `${API_GROUP}/${groupId}/routes?limit=1000`,
                data: data.body,
            }).then((res) => {
                resolve(res);
            }, reject);
        });
    }

    function pictures(groupId, data) {
        data = data || {};
        return new Promise((resolve, reject) => {
            http.fetch({
                method: GET,
                url: `${API_GROUP}/${groupId}/pictures?limit=1000`,
                data: data.body,
            }).then((res) => {
                resolve(res);
            }, reject);
        });
    }

    function challenges(groupId, data) {
        data = data || {};
        return new Promise((resolve, reject) => {
            http.fetch({
                method: GET,
                url: `${API_GROUP}/${groupId}/challenges?limit=1000`,
                data: data.body,
            }).then((res) => {
                resolve(res);
            }, reject);
        });
    }

    function users(groupId, data) {
        data = data || {};
        return new Promise((resolve, reject) => {
            http.fetch({
                method: GET,
                url: `${API_GROUP}/${groupId}/users?limit=1000`,
                data: data.body,
            }).then((res) => {
                resolve(res);
            }, reject);
            /*
            if (groupUsers.data ?? {}.length > 0) {
                return resolve(groupUsers);
            } else {
                http.fetch({
                    method: GET,
                    url: `${API_GROUP}/${groupId}/users`,
                    data: data.body,
                }).then((res) => {
                    groupUsers = res;
                    resolve(res);
                }, reject);
            }
            */
        });
    }

    function messages(groupId, data) {
        data = data || {};
        return new Promise((resolve, reject) => {
            http.fetch({
                method: GET,
                url: `${API_GROUP}/${groupId}/messages?limit=1000`,
                params: data.body,
            }).then((res) => {
                resolve(res);
            }, reject);
        });
    }

    function drawings(groupId, data) {
        data = data || {};
        return new Promise((resolve, reject) => {
            http.fetch({
                method: GET,
                url: `${API_GROUP}/${groupId}/drawings?limit=1000`,
                data: data.body,
            }).then((res) => {
                resolve(res);
            }, reject);
        });
    }

    function posts(groupId, data) {
        data = data || {};
        return new Promise((resolve, reject) => {
            http.fetch({
                method: GET,
                url: `${API_GROUP}/${groupId}/posts?limit=1000`,
                data: data.body,
            }).then((res) => {
                resolve(res);
            }, reject);
        });
    }

    function points(groupId, data) {
        data = data || {};
        return new Promise((resolve, reject) => {
            http.fetch({
                method: GET,
                url: `${API_GROUP}/${groupId}/points?limit=1000`,
                data: data.body,
            }).then((res) => {
                resolve(res);
            }, reject);
        });
    }

    function links(groupId, data) {
        data = data || {};
        return new Promise((resolve, reject) => {
            http.fetch({
                method: GET,
                url: `${API_GROUP}/${groupId}/links?limit=1000`,
                data: data.body,
            }).then((res) => {
                resolve(res);
            }, reject);
        });
    }

    return {
        groups,
        group,
        routes,
        challenges,
        pictures,
        users,
        messages,
        drawings,
        posts,
        points,
        links,
    }
}