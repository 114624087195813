<template>
  <div :id="mapId" style="width: 100%; height: 100%"></div>
</template>
<script>
import mapkit from "@solidsilver/mapkitjs";
import {computed, onMounted, toRefs, watch} from 'vue';
import useGroupComp from "@/core/services/composables/useGroupComp";
import {useRoute} from "vue-router";
import {
  PUSHER_channelGroup, PUSHER_eventDrawingCreated,
  PUSHER_eventLocationCreated,
  PUSHER_eventPointCreated,
  PUSHER_eventRouteCreated
} from "@/config/api";
import usePusherComp from "@/core/services/composables/usePusherComp";
import useAuthComp from "@/core/services/composables/useAuthComp";
import {Toast} from "vant";
import useRouteComp from "@/core/services/composables/useRouteComp";

export default {
  props: {
    mapType: String,
    mapCenterCount: Number,
    mapCenterGroupCount: Number,
    mapShowAllUserCoordinate: Boolean,
    mapNorthCount: Number,
    mapFocusPoint: Number,
    // onOnShowPoint: Function,
  },
  emits: ['showPoint', 'toggleGpsFocus'],
  setup: function (props, context) {
    const {mapShowAllUserCoordinate} = toRefs(props);
    const mapId = computed(() => 'map_kit_id');
    const groupComp = useGroupComp();
    const route = useRoute();
    const pusherComp = usePusherComp();
    const authComp = useAuthComp();
    const routeComp = useRouteComp();
    const groupId = route.params.groupId ? route.params.groupId : 402;
    const pointId = route.params.pointId ? route.params.pointId : 0;
    const channel = pusherComp.subscribe(`${PUSHER_channelGroup}${groupId}`);
    let showAllCoordinate = mapShowAllUserCoordinate.value;
    let allUserCoordinate = [];

    const iconMFactory = function (coordinate, options) {
      const marker = document.createElement("div"),
          div = document.createElement("div"),
          url = options.data.icon,
          style = "background-image:url('" + url + "')";
      marker.setAttribute('style', style);
      marker.className = "circle-marker";
      marker.appendChild(div);
      return marker;
    };

    const pointMFactory = function (coordinate, options) {
      // console.log('pointMFactory', options.data);
      if (options.data.type === "audio_guide") {
        return imageMFactory('/media/icon/ic_pin_avg_point_normal@3x.png');
      }
      if (options.data.type === "speed_cam") {
        return textMFactory(options.data.params.speed_limit ?? 0, "red font-11");
      }
      return textMFactory(options.data.emoji ? options.data.emoji : options.data.title[0]);
    };

    const textMFactory = function (textContent, extraClass) {
      const marker = document.createElement("div"),
          div = document.createElement("div");
      div.textContent = textContent;
      marker.className = `pin-marker small ${extraClass}`;
      marker.appendChild(div);
      return marker;
    };

    const imageMFactory = function (imageUrl, extraClass) {
      const marker = document.createElement("div"),
          div = document.createElement("div"),
          url = imageUrl,
          style = 'background-image:url(' + url + ');';
      div.setAttribute('style', style);
      marker.className = `pin-marker white small ${extraClass}`;
      marker.appendChild(div);
      return marker;
    };

    const userMFactory = function (coordinate, options) {
      const marker = document.createElement("div"),
          div = document.createElement("div"),
          url = options.data.avatar_url ?? '/media/images/user.png',
          style = 'background-image:url(' + url + ');';
      div.setAttribute('style', style);
      marker.className = "pin-marker white";
      marker.appendChild(div);
      return marker;
    };

    const addUserLocations = function (map, creator, location) {
      let speed = (location.params ?? {}).speed ?? -1;
      if (speed < 0) speed = 0;
      speed = speed * 3.6;
      location.user_id = creator.id;
      location.speed = parseFloat(speed).toFixed(2);
      allUserCoordinate = allUserCoordinate.filter(item => Number(item.coordinate.user_id) !== Number(creator.id))
      if (Number(creator.id) === 0) {
        allUserCoordinate.push({data: creator, coordinate: location});
        addUserMarker(map);
        return;
      }
      groupComp.users(groupId).then(value => {
        const user = value.data.items.find((item) => Number(item.id) === Number(creator.id));
        allUserCoordinate.push({data: user, coordinate: location});
        addUserMarker(map);
      })
    }

    const geocoder = new mapkit.Geocoder({
      language: "zh-Hant"
    });

    const centerSelf = function (map, self, toggleGpsFocus = false) {
      authComp.gps().then(position => {
        const lat = position.coords.latitude;
        const lng = position.coords.longitude;
        const location = {
          lat: lat,
          lng: lng,
        }
        addUserLocations(map, self, location);
        map.setCenterAnimated(new mapkit.Coordinate(lat, lng), true);
        context.emit('toggleGpsFocus', toggleGpsFocus);
      }).catch(err => {
        Toast({
          message: err.message,
          position: 'bottom',
        });
      });
    }

    const addPointMarker = function (map, v) {
      const markerCoordinate = new mapkit.Coordinate(v.lat, v.lng);
      const annotation = new mapkit.Annotation(markerCoordinate, pointMFactory, {
        title: v.title,
        subtitle: v.description,
        data: v,
        size: {width: 32, height: 32},
      });
      map.addAnnotation(annotation);
      if(annotation.data.id == pointId){
        annotation.selected = true;
        setTimeout(function(){
        //   map.setCenterAnimated(new mapkit.Coordinate(annotation.data.lat, annotation.data.lng), true);
          var zoomlevel = new mapkit.CoordinateSpan(.0001);
          var region = new mapkit.CoordinateRegion(new mapkit.Coordinate(annotation.data.lat, annotation.data.lng), zoomlevel);
          map.setRegionAnimated(region);
        }, 700);
        context.emit('showPoint', v.type, v.id);
      }
    }

    const addRouteMarker = function (map, v, show = false) {
      const coordinates = v.coordinates.filter(function (c) {
        if (c.lat !== 0 && c.lng !== 0) {
          return c;
        }
      });
      const coords = coordinates.map(function (c) {
        return new mapkit.Coordinate(c.lat, c.lng);
      });
      const style = new mapkit.Style({
        lineWidth: 4,
        lineJoin: "round",
        strokeColor: v.color
      });
      const line = new mapkit.PolylineOverlay(coords, {style: style});
      map.addOverlay(line);
    }

    const addDrawingMarker = function (map, v) {
      const points = [[v.tl_lat, v.tl_lng], [v.tr_lat, v.tr_lng], [v.bl_lat, v.bl_lng]];
      const coordinates = points.map(function (point) {
        return new mapkit.Coordinate(point[0], point[1]);
      });
      const style = new mapkit.Style({
        strokeColor: "#F00",
        strokeOpacity: .2,
        lineWidth: 2,
        lineJoin: "round",
        lineDash: [2, 2, 6, 2, 6, 2]
      });
      const polygonOverlay = new mapkit.PolygonOverlay(coordinates, {style: style});

      const coordinate = new mapkit.Coordinate(v.bl_lat, v.tr_lng);
      // const annotation = new mapkit.Annotation(coordinate, imageMFactory, {
      //   data: v,
      // });
      // map.addAnnotation(annotation);
      const marker = {
        url: {1: v.url},
        anchorOffset: new DOMPoint(0, -16),
        data: v,
      };
      const annotation = new mapkit.ImageAnnotation(coordinate, marker);
      polygonOverlay.addAnnotation(annotation);
      map.addOverlay(polygonOverlay);
    }

    const focusPoint = function (map){
      const point = map.annotations.filter(item => item.data.id == pointId);
      // console.log(point)
        map.showItems(point,
          {
            animate: true,
            padding: new mapkit.Padding(60, 25, 60, 25)
          },
      );
    }

    const addUserMarker = function (map) {
      allUserCoordinate.forEach(function (v) {
        const lat = Number(v.coordinate.lat);
        const lng = Number(v.coordinate.lng);
        v.data.coordinates = v.coordinate;
        const markerCoordinate = new mapkit.Coordinate(lat, lng);
        const userLoc = map.annotations.find((item) => Number(item.data.id) === Number(v.coordinate.user_id) && item.data.username !== undefined);
        if (typeof userLoc != "undefined") {
          map.removeAnnotation(userLoc);
        }
        if (!showAllCoordinate) {
          return;
        }
        // fix after
        geocoder.reverseLookup(markerCoordinate, function () {
          v.data['address'] = arguments[1].results[0].formattedAddress;
        });

        const annotationUserLoc = new mapkit.Annotation(markerCoordinate, userMFactory, {
          callout: userAnnotationCallout,
          title: v.data.username,
          subtitle: v.data.full_name,
          data: v.data,
        });
        map.addAnnotation(annotationUserLoc);
      });
    }

    const CALLOUT_OFFSET = new DOMPoint(0, 5);
    const userAnnotationCallout = {
      calloutElementForAnnotation: function (annotation) {
        return calloutForUserAnnotation(annotation);
      },

      calloutAnchorOffsetForAnnotation: function (annotation, element) {
        return CALLOUT_OFFSET;
      },

      calloutAppearanceAnimationForAnnotation: function (annotation) {
        return ".4s cubic-bezier(0.4, 0, 0, 1.5) 2s 1 normal scale-and-fadein";
      }
    };

    function calloutForUserAnnotation(annotation) {
      const br = document.createElement("br");
      const div = document.createElement("div");
      div.className = "point-box-container";

      const divTextContainer = document.createElement("div");
      divTextContainer.className = "point-body-container";
      div.appendChild(divTextContainer);

      const title = divTextContainer.appendChild(document.createElement("p"));
      title.className = 'title';
      title.textContent = annotation.data.full_name ?? 'Guest';

      divTextContainer.appendChild(br);
      divTextContainer.appendChild(br);

      const shareicon = divTextContainer.appendChild(document.createElement("img"));
      shareicon.setAttribute("src", "/media/icon/ic_basic_share_normal.png");
      shareicon.textContent = "hello";
      shareicon.className = "share-icon";
      divTextContainer.appendChild(shareicon);

      const latlong = divTextContainer.appendChild(document.createElement("text"));
      latlong.textContent = annotation.coordinate.latitude.toFixed(3) + "," + annotation.coordinate.longitude.toFixed(3);
      latlong.className = 'text-indent gray font14';
      divTextContainer.appendChild(latlong);

      const place = divTextContainer.appendChild(document.createElement("text"));
      place.textContent = annotation.data.address ?? 'unknown address';
      place.className = 'text-indent-address gray font14';
      divTextContainer.appendChild(place);

      const wind = divTextContainer.appendChild(document.createElement("text"));
      // if (annotation.data.coordinates.speed < 0) annotation.data.coordinates.speed = 0
      wind.textContent = "時速 " + annotation.data.coordinates.speed + " km/h 。";
      wind.className = 'text-indent';
      divTextContainer.appendChild(wind);

      return div;
    }

    // gyroscope


    // if (window.DeviceOrientationEvent) {
    //   // Listen for the deviceorientation event and handle the raw data
    //   window.addEventListener('deviceorientation', function(event) {
    //     var compassdir;

    //     if(event.webkitCompassHeading) {
    //       // Apple works only with this, alpha doesn't work
    //       compassdir = event.webkitCompassHeading;
    //       console.log(compassdir);
    //     }
    //     else compassdir = event.alpha;
    //   });
    // }
    // end gyroscope

    // window.addEventListener("deviceorientation", function(event){
    //   var absolute = event.absolute;
    //   var alpha    = event.alpha;
    //   var beta     = event.beta;
    //   var gamma    = event.gamma;
    //   console.log('absolute : '+absolute);
    //   console.log('alpha : '+alpha);
    //   console.log('beta : '+beta);
    //   console.log('gamma : '+gamma);
    // }, true);

    // const imageOverlay = function (v, e) {
    //   console.log(v, e);
    // }

    onMounted(() => {
      let self = authComp.user();
      const map = new mapkit.Map(mapId.value);
      map.region = new mapkit.CoordinateRegion(
          new mapkit.Coordinate(25.041013605289393, 121.41704925720751),
          new mapkit.CoordinateSpan(0.5335031870663727, 0.4348213499090434)
      );

      centerSelf(map, self, true);
      groupComp.routes(groupId).then(value => {
        value.data.items.forEach(function (v) {
          addRouteMarker(map, v);
        });
      })
      groupComp.points(groupId).then(value => {
        value.data.items.forEach(function (v) {
          addPointMarker(map, v);
        });
      })
      //REMARK ROUTES
      // groupComp.drawings(groupId).then(value => {
      //   // bl_lat: 25.022915
      //   // bl_lng: 121.539071
      //   // created_at: "2021-03-29 13:12:02"
      //   // deleted_at: null
      //   // filename: "Oiw3FqCHACWgounOq6XLBJXVZYSs3cjBiaII01Aa.png"
      //   // group_id: 402
      //   // id: 545
      //   // parent_id: null
      //   // tl_lat: 25.056476
      //   // tl_lng: 121.539071
      //   // tr_lat: 25.056476
      //   // tr_lng: 121.563755
      //   // updated_at: "2021-03-29 13:12:02"
      //   // url: "https://staging.api.doweing.com/v1/files/Oiw3FqCHACWgounOq6XLBJXVZYSs3cjBiaII01Aa.png"
      //   // user_id: 9
      //   // console.log(value.data);
      //   value.data.items.forEach(function (v) {
      //     console.log(v);
      //     // addDrawingMarker(map, v); //perlu cek
      //
      //     const coordinate = new mapkit.Coordinate(v.bl_lat, v.tr_lng);
      //     // const annotation = new mapkit.Annotation(coordinate, imageMFactory, {
      //     //   data: v,
      //     // });
      //     // map.addAnnotation(annotation);
      //
      //     const mapContainer = [document.querySelector('.rt-root').width, document.querySelector('.rt-root').height];
      //     const marker = {
      //       url: {1: v.url},
      //       anchorOffset: new DOMPoint(0, -16),
      //       data: v,
      //       size: {
      //         width: Number(v.tl_lng) * Number(mapContainer[0]) / 360,
      //         height: (Number(v.tl_lng) * Number(mapContainer[1]) / 180)
      //       },
      //     };
      //     const annotation = new mapkit.ImageAnnotation(coordinate, marker);
      //     // map.addAnnotation(annotation);
      //
      //     // const markerCoordinate = new mapkit.Coordinate(v.lat, v.lng);
      //     // const annotation = new mapkit.Annotation(markerCoordinate, pointMFactory, {
      //     //   callout: landmarkAnnotationCallout,
      //     //   title: v.title,
      //     //   subtitle: v.description,
      //     //   data: v,
      //     //   size: {width: 32, height: v.tl_lng*map.width/180},
      //     // });
      //     // map.addAnnotation(annotation);
      //
      //     // const customOverlay = new mapkit.TileOverlay((x, y, z, a, data) => {
      //     //   console.log(z, y, z, a, data);
      //     //   return v.url;
      //     // });
      //     // customOverlay.data = {
      //     //   subdomain: "staging",
      //     //   lang: mapkit.language
      //     // };
      //     // map.addTileOverlay(customOverlay);
      //   });
      // })
      channel.bind(PUSHER_eventLocationCreated, v => {
        addUserLocations(map, v.creator, v.location);
      })
      channel.bind(PUSHER_eventPointCreated, v => {
        // {"event":"point.created","channel":"group.402","data":{"group":{"id":402,"name":"New Group hello"},"creator":{"id":67,"username":"kegat","full_name":"Kegat"},
        // "point":{"id":6860,"parent_id":null,"type":"poi","emoji":"📍","title":"point -","description":"Poin 1","lat":25.038128378529784,"lng":121.57619718945615,"created_at":"2021-04-14 09:17:53","updated_at":"2021-04-14 09:17:53","deleted_at":null}
        // }}
        // console.log('point', v);
        addPointMarker(map, v.point);
        map.setCenterAnimated(new mapkit.Coordinate(v.point.lat, v.point.lng), true);
      })
      // addPointMarker(map, {"id":6860,"parent_id":null,"type":"poi","emoji":"📍","title":"point -","description":"Poin 1","lat":25.038128378529784,"lng":121.57619718945615,"created_at":"2021-04-14 09:17:53","updated_at":"2021-04-14 09:17:53","deleted_at":null});
      channel.bind(PUSHER_eventRouteCreated, v => {
        // {"event":"route.created","channel":"group.402","data":{"group":{"id":402,"name":"New Group hello"},"creator":{"id":67,"username":"kegat","full_name":"Kegat"},
        // "route":{"id":610,"parent_id":null,"title":"Kegat's Route","description":null,"tracking_duration":4000,"color":"#2196f3","created_at":"2021-04-14 09:18:22","updated_at":"2021-04-14 09:18:22","deleted_at":null}
        // }}
        // console.log('route-created', v);
        routeComp.route(v.route.id).then(value => {
          // console.log('route-loaded', v);
          addRouteMarker(map, value.data, true);
          // map.setCenterAnimated(new mapkit.Coordinate(v.route.lat, v.route.lng), true);
        })
        // addRouteMarker(map, v.route); // perlu cek
        // map.setCenterAnimated(new mapkit.Coordinate(v.route.lat, v.route.lng), true);
      })
      channel.bind(PUSHER_eventDrawingCreated, v => {
        // {"event":"drawing.created","channel":"group.402","data":{"group":{"id":402,"name":"New Group hello"},"creator":{"id":67,"username":"kegat","full_name":"Kegat"},
        // "drawing":{"id":551,"parent_id":null,"filename":"kehA9z0wdPAqn6fEZWIV6f11JXD7VkCb5FJeI2vS.png","url":"https://staging.api.doweing.com/v1/files/kehA9z0wdPAqn6fEZWIV6f11JXD7VkCb5FJeI2vS.png","tl_lat":25.04105210954452,"tl_lng":121.57557727687382,"tr_lat":25.04105210954452,"tr_lng":121.57812308006245,"bl_lat":25.037767138354074,"bl_lng":121.57557727687382,"created_at":"2021-04-14 09:19:25","updated_at":"2021-04-14 09:19:25","deleted_at":null}
        // }}
        // console.log('drawing', v);
        addRouteMarker(map, v.drawing); // perlu cek
        map.setCenterAnimated(new mapkit.Coordinate(v.drawing.lat, v.drawing.lng), true);
      })
      map.addEventListener("select", (v) => {
        // console.log(v)
        if (v.annotation === undefined) {
          // console.log(v);
          return;
        }
        if (v.annotation.data.type !== undefined) {
          context.emit('showPoint', v.annotation.data.type, v.annotation.data.id);
        } else {
          map.addAnnotation(v.annotation, {
            animate: true,
            padding: new mapkit.Padding(60, 25, 60, 25)
          })
        }
      })
      map.addEventListener("region-change-start", (v, e) => {
        context.emit('toggleGpsFocus', false);
      })

      watch(() => props.mapShowAllUserCoordinate, (newValue, oldValue) => {
        showAllCoordinate = newValue;
        addUserMarker(map);
      })
      watch(() => props.mapCenterGroupCount, (newValue, oldValue) => {
        const users = map.annotations.filter(item => item.data.username !== undefined);
        map.showItems(users,
            {
              animate: true,
              padding: new mapkit.Padding(60, 25, 60, 25)
            },
        );
      })
      watch(() => props.mapFocusPoint, (newValue, oldValue) => {
        const point = map.annotations.filter(item => item.data.id == newValue);
        map.showItems(point,
            {
              animate: true,
              padding: new mapkit.Padding(120, 50, 120, 50)
            },
        );
      })
      watch(() => props.mapCenterCount, (newValue, oldValue) => {
        centerSelf(map, self, true);
      })
      watch(() => props.mapType, (newValue, oldValue) => {
        map.mapType = newValue;
      })
      watch(() => props.mapNorthCount, (newValue, oldValue) => {
        map.setRotationAnimated(0, true);
        context.emit('toggleGpsFocus', false);
      })
    });

    return {
      mapId,
    }
  },
  components: {}
}
</script>
