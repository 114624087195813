<template>
  <audio
      :src="url"
      ref="audioPlayer"
      @timeupdate="onPlaying"
      @ended="stop"
  >
    Your browser does not support the
    <code>audio</code> element.
  </audio>
  <div class="player">
    <div class="progress-container">
      <div class="time-before">{{ currentTimeString }}</div>
      <van-slider v-model="seekValue" active-color="#000000" bar-height="5" @change="onSeek">
        <template #button>
          <div class="custom-button"></div>
        </template>
      </van-slider>
      <div class="time-after">{{ endTimeString }}</div>
    </div>
    <div class="control">
      <van-icon @click="play" v-if="!playing" name="play-circle" size="2rem"/>
      <van-icon @click="pause" v-else name="pause-circle" size="2rem"/>
    </div>
    <div class="bottom-control" @click="onviewPoint">
        <span class="view">
          <span class="bottom-control-view">
            <van-icon name="location-o" size="1.5rem"/>
          </span>
          <br>View
        </span>
      <span class="speed">
          <span class="bottom-control-text">{{ 1 }}x</span>
          <br>Speed
        </span>
    </div>
  </div>
</template>

<script>
import moment from "moment";

const timeFormat = 'mm:ss';
const defaultTime = "00:00";

export default {
  name: "App",
  props: {
    url: {
      type: String,
      required: true,
    },
  },
  emits:['viewPoint'],
  data() {
    return {
      playing: false,
      seekValue: 0,
      currentTimeString: defaultTime,
      endTimeString: defaultTime,
    };
  },
  methods: {
    onviewPoint(){
      this.$emit('viewPoint');
    },
    play() {
      this.playing = true;
      this.$refs.audioPlayer.play();
    },
    pause() {
      this.playing = false;
      this.$refs.audioPlayer.pause();
    },
    stop() {
      const {audioPlayer} = this.$refs;
      audioPlayer.pause();
      audioPlayer.currentTime = 0;
      this.playing = false;
    },
    setSpeed(speed) {
      this.$refs.audioPlayer.playbackRate = speed;
    },
    onPlaying() {
      const {audioPlayer} = this.$refs;
      if (!audioPlayer) {
        return;
      }
      this.seekValue = (audioPlayer.currentTime / audioPlayer.duration) * 100;
      this.currentTimeString = moment.utc(audioPlayer.currentTime * 1000).format(timeFormat);
      this.endTimeString = moment.utc((isNaN(audioPlayer.duration) ? 1 : audioPlayer.duration) * 1000).format(timeFormat);
    },
    onSeek() {
      const {audioPlayer} = this.$refs;
      const seekto = audioPlayer.duration * (this.seekValue / 100);
      audioPlayer.currentTime = seekto;
    },
  },
};
</script>
<style scoped>
.bottom-control {
  text-align: center;
  margin-left: -10px;
  margin-top: 20px;
}

.bottom-control > .view {
  width: 20px;
  height: 20px;
  display: inline-block;
}

.bottom-control > .speed {
  width: 20px;
  height: 20px;
  margin-left: 4rem;
  display: inline-block;
}

.bottom-control-view {
  margin-left: 5px;
}

.bottom-control-text {
  margin-left: 2px;
  font-size: 22px;
}

.player {
  position: relative;
  margin-left: 15%;
  margin-right: 15%;
}

.progress-container {
  display: inline;
}

.time-before {
  float: left;
  margin-top: 10px;
}

.time-after {
  float: right;
  margin-top: 10px;
}

.progress {
  position: absolute;
  border-radius: 20px;
  height: 4px;
  left: 0;
  top: 0;
  background-color: black;
}

.progress-background {
  position: absolute;
  border-radius: 20px;
  height: 2.5px;
  left: 0;
  top: 0.5px;
  background-color: rgba(61, 60, 60, 0.2);
  width: 100%;
}

.control {
  position: relative;
  display: block;
  padding-top: 18px;
  text-align: center;
}

.custom-button {
  width: 12px;
  height: 12px;
  font-size: 10px;
  line-height: 18px;
  text-align: center;
  border-radius: 100px;
  background-color: #000000;
  color: #fff;
}
</style>
