<template>
  <van-image
      v-show="!voiceGuide"
      class="logo-top-left"
      width="50px"
      style="margin: 2% 2%"
      src="/media/icon/ic_pin_avg_point_normal@2x.png"
  />
  <div class="container mt-3">
    <div class="header-content">
      {{ title }}
    </div>
    <div class="body-content my-3">
      <van-image
          width="100%"
          :style="{'min-height':'300px'}"
          fit="contain"
          :src="picture"
      />
    </div>
    <base-audio-player :url="audioUrl" @viewPoint="onViewPoint"></base-audio-player>
  </div>
</template>

<script>
import BaseAudioPlayer from '@/view/components/ui/BaseAudioPlayer.vue';
import {ref, toRefs, watch} from "vue";
import usePointComp from "@/core/services/composables/usePointComp";

export default {
  props: {
    pointId: Number,
    voiceGuide: Boolean
  }, 
  emits: ['viewPoint'],
  setup(props,context) {
    const {pointId, voiceGuide} = toRefs(props,voiceGuide);
    const pointComp = usePointComp();
    const description = ref();
    const title = ref();
    const picture = ref();
    const audioUrl = ref("");

    const onUpdateInterface = function (id) {
      pointComp.point(id).then(data => {
        description.value = data.data.description;
        title.value = data.data.title;
      })
      pointComp.pictures(id).then(pict => {
        picture.value = (pict.data.items[0] ?? {}).url;
      });
      pointComp.audios(id).then(audio => {
        audioUrl.value = (audio.data.items[0] ?? {}).url;
      });
    };
    onUpdateInterface(pointId.value);
    const onViewPoint = () =>{
      context.emit('viewPoint', props.pointId);
    }
    watch(() => props.pointId, (newValue, oldValue) => {
      onUpdateInterface(newValue);
    })
    return {description, title, picture, audioUrl, onViewPoint}
  },
  components: {
    BaseAudioPlayer
  }
}
</script>

<style scoped>
.container {
  position: relative;
  /* margin-top: 10%; */
  padding-bottom: 30px;
}

.header-content {
  text-align: center;
  font-weight: bold;
}

.img-content {
  width: 100%;
}

.floating-close {
  margin: 0;
  bottom: -20px;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
</style>

