import {useAuth} from '@websanova/vue-auth/src/v3.js';
import {API_POINT, GET} from "@/config/api";

export default function usePointComp() {
    const http = useAuth();

    function pictures(pointId, data) {
        data = data || {};
        return new Promise((resolve, reject) => {
            http.fetch({
                method: GET,
                url: `${API_POINT}/${pointId}/pictures`,
                data: data.body,
            }).then((res) => {
                resolve(res);
            }, reject);
        });
    }

    function audios(pointId, data) {
        data = data || {};
        return new Promise((resolve, reject) => {
            http.fetch({
                method: GET,
                url: `${API_POINT}/${pointId}/audios`,
                data: data.body,
            }).then((res) => {
                resolve(res);
            }, reject);
        });
    }

    function point(pointId, data) {
        data = data || {};
        return new Promise((resolve, reject) => {
            http.fetch({
                method: GET,
                url: `${API_POINT}/${pointId}`,
                data: data.body,
            }).then((res) => {
                resolve(res);
            }, reject);
        });
    }

    function videos(pointId, data) {
        data = data || {};
        return new Promise((resolve, reject) => {
            http.fetch({
                method: GET,
                url: `${API_POINT}/${pointId}/videos`,
                data: data.body,
            }).then((res) => {
                resolve(res);
            }, reject);
        });
    }

    function comments(pointId, data) {
        data = data || {};
        return new Promise((resolve, reject) => {
            http.fetch({
                method: GET,
                url: `${API_POINT}/${pointId}/comments`,
                data: data.body,
            }).then((res) => {
                resolve(res);
            }, reject);
        });
    }

    function checkins(pointId, data) {
        data = data || {};
        return new Promise((resolve, reject) => {
            http.fetch({
                method: GET,
                url: `${API_POINT}/${pointId}/checkins`,
                data: data.body,
            }).then((res) => {
                resolve(res);
            }, reject);
        });
    }



    return {
        pictures,
        audios,
        videos,
        comments,
        checkins,
        point
    }
}