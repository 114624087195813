<template>
  <div class="container mt-2  mb-2">
    <div class="header-content">
      <span class="name"><b>Irene Brown</b></span>
      <span class="logo">
          <van-icon name="share-o" color="gray" size="2rem"/>
        </span>
    </div>
    <div class="body-content">
      <br><br><span>153, 158, 776</span>
      <br><span>tai pei some thing</span>
      <br><span style="color:black">tai pei 62/kmh</span>
    </div>
  </div>
</template>

<style scoped>
.container {
  margin-left: 8%;
  margin-right: 6%;
}

.header-content {
  font-size: 20px;
}

.header-content > .name {
  float: left;
}

.header-content > .logo {
  float: right;
}

.body-content {
  color: gray;
}
</style>

<script>
export default {
  setup() {
  },
  methods: {}
}
</script>

